import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import { ICountryData, getCountryDataList } from 'countries-list'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { geoSelector } from '@/core/services/App/store/AppSelector'
import { setToast } from '@/core/services/Toast/store/ToastSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import useBackendError from '@/hooks/useBackendError'
import useVefifyToken from '@/hooks/useVerifyToken'
import useWindowSize from '@/hooks/useWindowSize'

import { removeEmpty } from '@/utils/removeEmpty'
import yup from '@/utils/yup-extended'

import { Button } from '@/components/Button'
import { Card } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Field } from '@/components/Field'
import { FieldCode } from '@/components/FieldCode'
import { FieldPhone } from '@/components/FieldPhone'
import { Icon } from '@/components/Icon'
import { Select } from '@/components/Select'
import { Step } from '@/components/Step'

import { AuthTop } from './components/AuthTop'
import { AuthWrap } from './components/AuthWrap'
import {
  RegisterModel,
  RegisterRequestCodeModel,
  UserType,
} from './models/RegisterModel'
import { getRegisterCodeThunk, registrationThunk } from './store/AuthSlice'
import './styles/Register.scss'

const cnRegister = cn('Register')

export const Register: FC = () => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { lessMobile } = useWindowSize()
  const geo = useAppSelector(geoSelector)
  const [setBackendError] = useBackendError()
  const { verify } = useVefifyToken()

  const [form, setForm] = useState<RegisterModel>(new RegisterModel())
  const [userType, setUserType] = useState<UserType>('person')
  const [step, setStep] = useState<number>(1)
  const [
    typeUser,
    //setTypeUser
  ] = useState<Array<{ value: UserType; title: string }>>([
    {
      value: 'person',
      title: 'Individual',
    },

    {
      value: 'company',
      title: 'Business',
    },
  ])

  const [countries, setCountries] = useState<ICountryData[]>([])

  useEffect(() => {
    if (verify) {
      navigate('/profile')
    }
  }, [verify])

  const SchemaPerson = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t('Enter the correct email'))
      .required(t('This field is required')),
    phone: yup
      .string()
      .trim()
      .isValidPhone(t('Enter the correct phone number'))
      .required(t('This field is required')),
    country: yup.string().required(t('This field is required')),
    address: yup.string().trim().required(t('This field is required')),
    address2: yup.string().trim().required(t('This field is required')),
    conditions: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    confirm: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    agree: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
  })

  const SchemaCompany = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t('Enter the correct email'))
      .required(t('This field is required')),
    phone: yup
      .string()
      .trim()
      .isValidPhone(t('Enter the correct phone number'))
      .required(t('This field is required')),
    org_name: yup.string().trim().required(t('This field is required')),
    org_site: yup
      .string()
      .trim()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        t('Enter correct url'),
      )
      .required(t('This field is required')),
    conditions: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    confirm: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    agree: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
  })

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    watch,
    setError,
    getValues,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      userType === 'company' ? SchemaCompany : SchemaPerson,
    ),
    defaultValues: new RegisterModel(),
  })

  const onSubmit = async (data: RegisterModel) => {
    delete data.agree
    delete data.confirm
    delete data.conditions

    const form = removeEmpty(data)

    if (step === 3) {
      form.confirm_code = '1'
    }

    try {
      const res = await dispatch(registrationThunk(form)).unwrap()
      // console.log('res', res)

      if (res && res.is_verify) {
        await getCode({
          type: data.type,
          email: data.email,
          phone: data.phone,
          country: data.country,
          address: data.address,
          address2: data.address2,
          input: 'email',
        })
        await getCode({
          type: data.type,
          email: data.email,
          phone: data.phone,
          country: data.country,
          address: data.address,
          address2: data.address2,
          input: 'phone',
        })

        next()
      }

      if (res && res.token) {
        navigate('/profile')
      }
    } catch (errors) {
      setBackendError(errors)
      const err = errors as AxiosResponse<{
        code_email?: string[]
        code_phone?: string[]
        phone?: string
        email?: string
      }>

      if (err && err.data && (err.data.email || err.data.phone)) {
        const name = err.data.email ? 'email' : 'phone'
        const message = t('Invalid user login')

        setError(name, { message })
      }

      if (err && err.data && (err.data.code_email || err.data.code_phone)) {
        const name = err.data.code_email ? 'code_email' : 'code_phone'
        const message = err.data.code_email
          ? t('The code email field is required.')
          : t('The code phone field is required.')

        setError(name, { message })

        dispatch(
          setToast({
            type: 'error',
            message: message,
          }),
        )
      }
    }
  }

  const getCode = async (data: RegisterRequestCodeModel) => {
    const values = getValues()
    let req = data

    if (data.type === 'company') {
      req = {
        ...req,
        org_name: values.org_name,
        org_site: values.org_site,
      }
    }

    try {
      await dispatch(getRegisterCodeThunk(req)).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const resendCode = async (input: 'phone' | 'email') => {
    if (!form) return

    await getCode({
      type: form.type,
      email: form.email,
      phone: form.phone,
      input,
    })
  }

  const next = () => {
    clearErrors()
    setTimeout(() => {
      setStep(step + 1)
    }, 100)

    reset(v => {
      return v
    })
  }

  const prev = () => {
    clearErrors()
    if (step !== 1) {
      setStep(step - 1)
      reset(v => {
        return v
      })
    }
  }

  const handleOnChangeCode = (
    value: string,
    field: 'code_email' | 'code_phone',
  ) => {
    if (value.length === 6) {
      setValue(field, value)
    }
  }

  useEffect(() => {
    const subscription = watch((form, { type }) => {
      if (type === 'change') {
        if (form) {
          setForm({
            ...new RegisterModel(),
            ...form,
          })
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [watch])

  useEffect(() => {
    setCountries(getCountryDataList())
  }, [])

  useEffect(() => {
    if (geo && countries.length) {
      const finded = countries.find(el => el.iso2 === geo.country)

      reset(values => {
        return {
          ...values,
          country: finded ? finded.name : '',
        }
      })
    }
  }, [geo, countries])

  return (
    <>
      <Helmet>
        <title>{t('Registration')}</title>
      </Helmet>
      <AuthWrap>
        <Card className={cnRegister('card')} resetStyle={lessMobile}>
          <AuthTop title={t('Registration')} line={!lessMobile} />

          <Step
            isPrev={step > 1}
            prev={prev}
            currentStep={step}
            length={3}
            className={cnRegister('steps')}
          />

          <form
            className={cnRegister('form')}
            onSubmit={handleSubmit(onSubmit)}
          >
            {step === 1 && (
              <div className={cnRegister('step')}>
                <div className={cnRegister('label')}>
                  {t('Choose account type')}
                </div>

                <div className={cnRegister('types')}>
                  {typeUser.map(el => (
                    <Controller
                      name='type'
                      key={el.value}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <label
                          htmlFor={el.value}
                          className={cnRegister('type', {
                            active: el.value === value,
                          })}
                        >
                          <Icon name={el.value} />
                          <div className={cnRegister('text')}>
                            {t(el.title)}
                          </div>
                          <input
                            id={el.value}
                            type='radio'
                            className={cnRegister('radio')}
                            checked={el.value === value}
                            onChange={() => {
                              onChange(el.value)
                              setUserType(el.value)
                            }}
                          />
                        </label>
                      )}
                    />
                  ))}
                </div>
              </div>
            )}

            {step === 2 && (
              <div className={cnRegister('step')}>
                <div className={cnRegister('label')}>
                  {t('Fill out the form')}
                </div>
                <div className={cnRegister('grid')}>
                  {userType === 'company' && (
                    <>
                      <Field
                        {...register('org_name')}
                        placeholder={t('Company name')}
                        errors={
                          errors && errors.org_name && errors.org_name.message
                        }
                        mb='none'
                      />
                      <Field
                        {...register('org_site')}
                        placeholder={t('Website address')}
                        // type='url'
                        errors={
                          errors && errors.org_site && errors.org_site.message
                        }
                        mb='none'
                      />
                    </>
                  )}

                  <Field
                    {...register('email')}
                    placeholder={t('Insert your email')}
                    errors={errors && errors.email && errors.email.message}
                    mb='none'
                  />

                  <Controller
                    name='phone'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FieldPhone
                        placeholder={t('Insert your number')}
                        errors={errors && errors.phone && errors.phone.message}
                        mb='none'
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />

                  {userType === 'person' && (
                    <>
                      <Controller
                        name='country'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={countries}
                            value={value}
                            handleCustomChange={v => onChange(v)}
                            returnValue='name'
                            optionLabel='name'
                            optionValue='name'
                            mb='none'
                            sizeInput='xxl'
                            isInput
                            errors={
                              errors && errors.country && errors.country.message
                            }
                          />
                        )}
                      />

                      <Field
                        {...register('address')}
                        placeholder={t('Address line 1')}
                        errors={
                          errors && errors.address && errors.address.message
                        }
                        mb='none'
                      />
                      <Field
                        {...register('address2')}
                        placeholder={t('Address line 2')}
                        // type='url'
                        errors={
                          errors && errors.address2 && errors.address2.message
                        }
                        mb='none'
                      />
                    </>
                  )}
                </div>

                <div className={cnRegister('checkboxes')}>
                  <Checkbox
                    errors={
                      errors && errors.conditions && errors.conditions.message
                    }
                    {...register('conditions')}
                  >
                    {t('I agree to the')}{' '}
                    <a
                      href='https://moniv.io/terms/terms-and-conditions'
                      target='_blank'
                    >
                      {t('Terms and Conditions')}
                    </a>
                  </Checkbox>
                  <Checkbox
                    errors={errors && errors.confirm && errors.confirm.message}
                    {...register('confirm')}
                  >
                    {t(
                      'I confirm that I understand the concept of cryptocurrency and the process of using cryptocurrency exchange services',
                    )}
                  </Checkbox>
                  <Checkbox
                    errors={errors && errors.agree && errors.agree.message}
                    {...register('agree')}
                  >
                    {t('I agree to the')},{' '}
                    <a
                      href='https://moniv.io/terms/privacy-policy'
                      target='_blank'
                    >
                      {t('Privacy Policy')}
                    </a>
                    ,{' '}
                    <a
                      href='https://moniv.io/terms/kyc-aml-policy'
                      target='_blank'
                    >
                      {t('KYC/AML Policy')}
                    </a>
                    ,{' '}
                    <a
                      href='https://moniv.io/terms/refund-policy'
                      target='_blank'
                    >
                      {t('Refund Policy')}
                    </a>
                    ,{' '}
                    {t(
                      'I confirm that I am not a PEP(Politically Significant Person), I am not US citizen.',
                    )}
                  </Checkbox>
                </div>
              </div>
            )}

            {step === 3 && (
              <div className={cnRegister('step')}>
                <div className={cnRegister('verify')}>
                  <div className={cnRegister('text')}>
                    {t('We have sent a confirmation code to', {
                      value: form.email,
                    })}
                  </div>
                  <div className={cnRegister('text')}>
                    {t('Insert code to access your account')}
                  </div>

                  <FieldCode
                    handleOnChange={e => handleOnChangeCode(e, 'code_email')}
                    placeholder='＊'
                    // success={isSuccsess}
                    // loading={isLoading}
                    resendCode={() => resendCode('email')}
                    error={
                      errors && errors.code_email && !!errors.code_email.message
                    }
                    className={cnRegister('code')}
                    isTime
                  />
                </div>

                <div className={cnRegister('verify')}>
                  <div className={cnRegister('text')}>
                    {t('We have sent a confirmation code to', {
                      value: form.phone,
                    })}
                  </div>
                  <div className={cnRegister('text')}>
                    {t('Insert code to access your account')}
                  </div>

                  <FieldCode
                    handleOnChange={e => handleOnChangeCode(e, 'code_phone')}
                    placeholder='＊'
                    // success={isSuccsess}
                    // loading={isLoading}
                    resendCode={() => resendCode('phone')}
                    error={
                      errors && errors.code_phone && !!errors.code_phone.message
                    }
                    className={cnRegister('code')}
                    isTime
                  />
                </div>
              </div>
            )}

            <div className={cnRegister('bottom')}>
              {step > 1 ? (
                <Button type='submit' size='lg' isLoading={isSubmitting}>
                  {step === 2 ? t('Continue') : t('Register')}
                </Button>
              ) : (
                <Button onClick={next}>{t('Continue')}</Button>
              )}
              <Button color='gray' size='lg' onClick={() => navigate('/')}>
                {t('Login')}
              </Button>
            </div>
          </form>
        </Card>
      </AuthWrap>
    </>
  )
}
