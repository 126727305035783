import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

import useWindowSize from '@/hooks/useWindowSize'

import './Footer.scss'

const cnFooter = cn('Footer')

export const Footer: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const { lessDesktop } = useWindowSize()

  return (
    <footer className={cnFooter()}>
      <div className={cnFooter('line')}>
        {!lessDesktop && (
          <div className={cnFooter('left')}>
            <div className={cnFooter('text')}>
              {t('All rights reserved by Manerio UAB')}
            </div>
          </div>
        )}
        <div className={cnFooter('center')}>
          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Company')}:</div>

            <a
              className={cnFooter('link')}
              href='https://moniv.io/about-us'
              target='_blank'
            >
              {t('About us')}
            </a>

            {/* <a className={cnFooter('link')}> {t('Careers')}</a> */}
          </div>
          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Legal')}:</div>

            <a
              className={cnFooter('link')}
              href='https://moniv.io/terms/terms-and-conditions'
              target='_blank'
            >
              {t('Terms and conditions')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://moniv.io/terms/privacy-policy'
              target='_blank'
            >
              {t('Privacy policy')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://moniv.io/terms/kyc-aml-policy'
              target='_blank'
            >
              {t('KYC/AML Policy')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://moniv.io/terms/refund-policy'
              target='_blank'
            >
              {t('Refund policy')}
            </a>
          </div>
          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Product')}:</div>

            <a
              className={cnFooter('link')}
              href='https://moniv.io/prices'
              target='_blank'
            >
              {t('Prices')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://moniv.io/limits'
              target='_blank'
            >
              {t('Limits')}
            </a>
          </div>
          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Information')}:</div>

            <a
              className={cnFooter('link')}
              href='https://moniv.io/questions/Exchange'
              target='_blank'
            >
              {t('Questions')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://moniv.io/contacts'
              target='_blank'
            >
              {t('Contacts')}
            </a>
          </div>
          {/* <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Account')}:</div>
            <a className={cnFooter('link')}>{t('Log In')}</a>
            <a className={cnFooter('link')}>{t('Sign up')}</a>
          </div> */}
        </div>

        <div className={cnFooter('right')}>
          {lessDesktop && (
            <div className={cnFooter('text', { bottom: true })}>
              {t('All rights reserved by Manerio UAB')}
            </div>
          )}
          <div className={cnFooter('text')}>
            {t(
              'Moniv.io is operated by Manerio UAB (Reg. No. 305928707) authorized by the Financial Crime Investigation Service under The Ministry of the Interior of the Republic of Lithuania. Registered address Eišiškių Sodų 18-oji g. 11, LT-02194, Vilnius, Lithuania.',
            )}
          </div>
        </div>
      </div>
    </footer>
  )
})
